import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { add, format, parse } from "date-fns";

import {
  Box,
  Button,
  Heading,
  Text,
  Link,
  Wrap,
  VStack,
  Flex,
  Skeleton,
} from "@chakra-ui/react";

import { ExternalLinkIcon as ReserveIcon } from "@chakra-ui/icons";
import {
  AvailabilityMatchAvailableAtBadge,
  SiteTypeBadge,
  SiteFilterBadge,
  Paper,
} from "~/components";

import { useAgencyValue } from "~/hooks";

import { maybePluralizeNoun } from "~/utils/words";

export function RecentOpenings({ ...rest }) {
  const { isSuccess, data } = useQuery({
    queryKey: ["RecentOpenings"],
    queryFn: async () => fetch("/api/m/recent").then((res) => res.json()),
    placeholderData: keepPreviousData,
  });

  return (
    <Skeleton isLoaded={isSuccess}>
      <RecentOpening availabilityMatch={data} {...rest} />
    </Skeleton>
  );
}

function RecentOpening({ availabilityMatch, ...rest }) {
  if (!availabilityMatch) {
    return <Box h="300px" {...rest}></Box>;
  }

  const { site, availableDate, availableLength } = availabilityMatch;

  const available = parse(
    availableDate.split("T")[0],
    "yyyy-MM-dd",
    new Date(),
  );
  const availableOn = format(available, "EEE M/dd");

  const facility = availabilityMatch.availabilityRequest.facility;
  const reserveUrl = useAgencyValue(
    {
      base: site.reserveUrl,
      AlbertaParks: `${site.reserveUrl}&arvdate=${format(
        available,
        "MM/dd/yyyy",
      )}`,
      ParksCanada: `${site.reserveUrl}&startDate=${format(
        available,
        "yyyy-MM-dd",
      )}&endDate=${format(
        add(available, {
          days: availabilityMatch.availabilityRequest.minStayLength,
        }),
        "yyyy-MM-dd",
      )}`,
      BCParks: `${site.reserveUrl}&startDate=${format(
        available,
        "yyyy-MM-dd",
      )}&endDate=${format(
        add(available, {
          days: availabilityMatch.availabilityRequest.minStayLength,
        }),
        "yyyy-MM-dd",
      )}`,
      OntarioParks: `${site.reserveUrl}&startDate=${format(
        available,
        "yyyy-MM-dd",
      )}&endDate=${format(
        add(available, {
          days: availabilityMatch.availabilityRequest.minStayLength,
        }),
        "yyyy-MM-dd",
      )}`,
      ACC: `${site.reserveUrl}&start_date=${format(
        available,
        "yyyy-MM-dd",
      )}&end_date=${format(
        add(available, {
          days: availabilityMatch.availabilityRequest.minStayLength,
        }),
        "yyyy-MM-dd",
      )}`,
    },
    facility.agency,
  );

  return (
    <Paper
      as={Flex}
      direction="column"
      alignItems="center"
      textAlign="center"
      borderWidth="1px"
      {...rest}
    >
      <Box mb={{ base: 4, md: 6 }}>
        <AvailabilityMatchAvailableAtBadge
          availabilityMatch={availabilityMatch}
          mr={2}
        />
      </Box>

      <Box mb={4}>
        <Text fontWeight="bold">{facility.name}</Text>
        <Text color="subtle">{facility.subName}</Text>
      </Box>
      <Box mb={4}>
        <Heading size="xs">
          {isNaN(site.name) ? site.name : `#${site.name}`}
        </Heading>
        <Text color="subtle">{site.group}</Text>
      </Box>
      <Box mb={4}>
        <Text fontWeight="bold">{availableOn}</Text>
        <Text color="subtle" whiteSpace="nowrap">
          <Text as="span">{maybePluralizeNoun(availableLength, "night")}</Text>
        </Text>
      </Box>

      <Wrap spacingX={2} spacingY="0px" mb={6}>
        <SiteTypeBadge type={site.type} />
        <SiteFilterBadge site={site} />
      </Wrap>

      <Button
        variant="secondary"
        rightIcon={<ReserveIcon />}
        as={Link}
        isExternal
        href={reserveUrl}
      >
        Book on {facility.agency.name}
      </Button>
    </Paper>
  );
}
